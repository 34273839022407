import { render, staticRenderFns } from "./AttributionToggle.vue?vue&type=template&id=4eb463a6&scoped=true&"
import script from "./AttributionToggle.vue?vue&type=script&lang=js&"
export * from "./AttributionToggle.vue?vue&type=script&lang=js&"
import style0 from "./AttributionToggle.vue?vue&type=style&index=0&id=4eb463a6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb463a6",
  null
  
)

/* custom blocks */
import block0 from "./AttributionToggle.vue?vue&type=custom&index=0&blockType=docs&lang=md"
if (typeof block0 === 'function') block0(component)

export default component.exports